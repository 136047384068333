import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, applyActionCode, sendEmailVerification, signOut, checkActionCode } from 'firebase/auth';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import ContainerBox from '../../shared/ContainerBox';

const EmailCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const [errorMessage, setErrorMessage] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    const doEmailCallback = async () => {
      const auth = getAuth();
      const params = new URLSearchParams(location.search);
      const actionCode = params.get('oobCode');
      const mode = params.get('mode');

      console.log('actionCode:', actionCode);
      console.log('mode:', mode);

      if (!actionCode || (mode !== 'verifyEmail' && mode !== 'verifyAndChangeEmail')) {
        setVerificationStatus('manual');
        return;
      }

      try {
        // Verify the action code is valid
        await checkActionCode(auth, actionCode);

        // Apply the action code
        await applyActionCode(auth, actionCode);
        setVerificationStatus('success');
      } catch (error) {
        console.error('Error applying action code:', error);
        setErrorMessage('The action code is invalid or has expired. Please request a new verification email.');
        setVerificationStatus('error');
        return; // Exit early if applyActionCode fails
      }

      // Optionally, you can attempt to reload the user
      const user = auth.currentUser;
      if (user) {
        try {
          await user.reload();
          setNewEmail(user.email);
        } catch (error) {
          console.error('Error reloading user:', error);
          if (error.code === 'auth/user-token-expired') {
            setErrorMessage('Your session has expired. Please sign in again.');
          }
        }
      }
    };

    doEmailCallback();
  }, [location]);

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      setErrorMessage('Failed to sign out. Please try again.');
    }
  };

  const resendVerificationEmail = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        await sendEmailVerification(user);
        setResendDisabled(true);
        setTimeout(() => setResendDisabled(false), 60000); // Disable for 1 minute
        alert('A new verification email has been sent. Please check your inbox.');
      } catch (error) {
        console.error('Error sending verification email:', error);
        alert('Failed to send verification email. Please try again later.');
      }
    } else {
      alert('No user is currently signed in. Please sign in and try again.');
    }
  };

  return (
    <ContainerBox>
      {verificationStatus === 'verifying' ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : verificationStatus === 'manual' ? (
        <Box sx={{ textAlign: 'center', padding: 3 }}>
          <Typography variant="h6">Please check your email for a verification link.</Typography>
          <Button
            variant="contained"
            onClick={resendVerificationEmail}
            disabled={resendDisabled}
            sx={{ mt: 2, mr: 2 }}
          >
            Resend Verification Email
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/dashboard')}
            sx={{ mt: 2 }}
          >
            Go to the Dashboard
          </Button>
        </Box>
      ) : verificationStatus && (
        <Box sx={{ textAlign: 'center', padding: 3 }}>
          <Typography variant="h6">Email verified successfully!</Typography>
          <Button
            variant="outlined"
            onClick={() => navigate('/dashboard')}
            sx={{ mt: 2 }}
          >
            Go to the Dashboard
          </Button>
        </Box>
      )}
    </ContainerBox>
  );
};

export default EmailCallback;
